@import 'colors';

.loadingSpinnerLogo {
  width: 80px;
  height: 49px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.welcomeToSynapse {
  width: 644px;
  height: 44px;
  font-family: Lato;
  font-size: 36px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #4381e2;
  position: absolute;
  left: 50%;
  top: calc(50% + 41px);;
  transform: translate(-50%, -50%);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
