@import 'colors';

.sideBarItem {
    display: flex;
    flex-direction: column;
    padding-bottom: 1rem;
    padding-top: 1rem;
    margin-bottom: 0.5rem;
    border-top: 1px solid $pale-grey-four;
    text-decoration: none;
    color: unset;
    &:hover {
        background-color: rgba($dark-sky-blue, 0.05);
        border-left: solid 0.2rem $dark-sky-blue;
    }

    .metaContainer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-right: 2rem;
      margin-left: 2rem;
      margin-bottom: 0.7rem;

      .metaStatus {
        width: 3rem;
        height: 0.3rem;
        border-radius: 2rem;
        background-color: $isDraft;
        &.approved {
          background-color: $isAccepted;
          border-color: $isAccepted;
        }
        &.submitted {
          background-color: $isSubmitted;
          border-color: $isSubmitted;
        }
        &.rejected {
          background-color: $isRejected;
          border-color: $isRejected;
        }
      }

      .metaTime {
        font-size: 0.7rem;
        color: $text;
        letter-spacing: 1px;
      }
    }

    .metaTitle {
      margin-left: 2rem;
      margin-bottom: 0.7rem;
      font-weight: bold;
    }
}
