@import 'colors';

.expansionPanel {
  &[class~='MuiPaper-root'] {
    box-shadow: none;
  }

  .headerContainer {
    display: flex;
    padding: 0 24px;
    background: linear-gradient(
      172deg, rgba($pale-grey-four, 0.5) 0%,
      rgba($pale-grey-four, 0.1)
      64%, $white 100%
    );

    .headerTitle {
      font-size: 0.8rem;
      font-weight: bold;
      letter-spacing: 1px;
      color: $requestCategory;
      margin-left: 1rem;
      margin-top: 4px;
    }

    .expansionIcon:hover  {
      background-color: transparent;
    }
  }

  .noFirstLine :first-child {
    border-top: none;
  }
}
