@import 'colors';

.backToLogin {
  cursor: pointer;
  display: flex;
  color: $blue-grey;
  font-size: 0.875rem;
  display: inline-flex;
  min-width: 7rem;
  margin: 3rem auto 0 8.7rem;
  .arrow {
    margin-top: 0.13rem;
    margin-right: 0.1rem;
  }
}
