@import 'colors';

.signUpPage {
    display: flex;
    flex-direction: row;
    height: 100vh;

    .signUpForm {
        display: flex;
        flex-direction: column;
        max-width: 40rem;
        min-width: 30rem;
        align-items: center;
        justify-content: center;
        overflow-y: auto;

        .signUpTitle {
            text-align: center;
            font-size: 2rem;
            padding-bottom: 2rem;
        }

        .confirmationTitle {
            font-size: 2.5rem;
            margin-bottom: 2rem;
        }

        .confirmationInstructions {
            width: 25rem;
            line-height: 1.63;
        }

        .emailIcon {
            margin-top: 4rem;
            
            img {
                width: 12.5rem;
                height: 12.5rem;
            }
        }

        .signUpInstructions {
            width: 24rem;
            padding-bottom: 1rem;
        }

        .instructions {
            font-size: 1rem;
            color: $black;
            margin-bottom: 1rem;
        }

        .requiredFields{
            font-size: 0.75rem;
            color: $black;
            font-style: italic;
        }

        .branding {
            width: auto;
            position: absolute;
            bottom: 0.5rem
          }
    }

    .signUpImage {
        flex-grow: 1;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }
}
