@import 'colors';
@import 'sizes';

.request {
  display: flex;
  justify-content: center;
  padding-bottom: $request-content-padding;
  padding-top: $request-content-padding;

  &.hasStatus {
    padding-top: $request-content-padding + $request-status-bar;
  }

  .requestContent {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-width: $request-content-size;
    padding-left: 0;
    padding-right: 0;
    margin-left: 2rem;
    margin-right: $request-actions-size;
    &.submitted {
      border-top: $warning 0.2rem outset;
    }
    &.approved {
      border-top: $success 0.2rem outset;
    }
    &.rejected {
      border-top: $error 0.2rem outset;
    }

    .loader {
      align-self: center;
      margin-top: 1.5rem;
    }
  }
}
.statusWrapper {
  height: $request-status-bar;
  .requestStatus {
    height: $request-status-bar;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
    font-size: 0.9rem;
    position: fixed;
    z-index: 2;
    width: calc(100% - #{$side-bar-width});

    &.submitted {
      background-color: $warning;
    }
    &.approved {
      background-color: $success;
    }
    &.rejected {
      background-color: $error;
    }
  }
}
