@import 'colors';

.loginForms {
  display: flex;
  flex-direction: column;
  align-items: center;
  
  .loginTitle {
    font-size: 1.5rem;
    color: $black;
    text-align: center;
    padding-bottom: 2rem;
  }

  .welcomeText {
    width: 25rem;
    font-size: 0.875rem;
    margin-bottom: 2.5rem;
  }

  .loginButton {
    width: 25rem;
    margin-top: 2.5rem;
  }

  .loginFooter {
    align-self: flex-start;
    margin-top: 0.7rem;
    margin-left: 2rem;
    .checkBox {
      font-size: 0.875rem;
      margin-left: 0.1rem;
    }
    .forgotPassword {
      margin-left: auto;
      margin-top: 0.75rem;
      font-size: 0.875rem;
      color: $blue-grey;
    }
  }

}

.redirectMessage {
  align-self: flex-start;
  margin-left: 3rem;
  margin-bottom: 1rem;
  font-size: 1rem;
  color: $black;
  .registerLink {
    margin-left: 1rem;
    display: inline-block;
    color: $blue-grey;
  }
}
