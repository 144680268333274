@import 'helpers';

.emptyRequest {
  align-items: center;
  display: flex;
  flex-direction: column;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);

  .emptyRequestIcon {
    box-shadow: $card-shadow;
    margin-bottom: 1.5rem;
  }

  .buttonIcon {
    margin-right: 0.5rem;
  }
}
