@import 'colors';
@import 'sizes';

.sideBar {
  background-color: $white;
  width: $side-bar-width;
  min-width: $side-bar-width;
  height: 100vh;
  overflow-y: auto;
  border-right: 1px solid $pale-grey-four;
  display: flex;
  flex-direction: column;
  align-items: center;

  .sedebarHeaderContainer {
    .logoPlaceholder {
      margin-top: 2rem;
      margin-bottom: 1.5rem;
      width: 9rem;
      min-height: 5rem;
    }
  
    .separator {
      background:$pale-grey-four;
      height:1px;
      border:none;
      width: 30%;
    }
  
    .sideBarHeader {
      padding: 1.5rem;
      font-weight: bold;
      font-size: 1rem;
      font-weight: bold;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      align-content: center;
      color: $text;
      letter-spacing: 0.5px;
  
      .createRequestButton {
        min-width: 2.5rem;
        margin-left: 3rem;
        padding: 0;
      }
    }
  }

  .expansionPanelContainer {
    overflow-y: auto;
    width: 100%;

    .sidebarEmpty {
      height: 60rem;
      max-height: 96%;
      border-top: solid $pale-grey-two 0.3rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .sidebarEmptyIcon {
        font-size: 2rem;
      }

      .emptyStateText {
        width: 80%;
        text-align: center;
      }
    }
  }
}
