@import 'colors';

$border-size: 2px;

.requiredLabel {
  padding-top: 1rem;
  padding-left: 1.5rem;
  color: $error;
}

.divider {
  padding: 1rem 1.5rem 1.5rem;
}
