@import 'colors';
@import 'sizes';

.topBar {
  display: flex;
  justify-content: flex-end;
  width: 100%;

  .notificationSpacer {
    padding-right: 1rem;
    display: flex;
  }
}
