@import 'colors';
@import 'fonts';

.requestModal {
  padding: 2.5rem;
  width: 40rem;

  .title {
    color: $greyish-brown-two;
    font-size: 1.25rem;
    font-weight: $bold;
    margin-bottom: 1.5rem;
  }

  .description {
    font-size: 0.875rem;
    font-weight: $normal;
    margin-bottom: 2rem;
  }

  .formPicker {
    width: 50%;

    .formLabel {
      color: $requestCategory;
      font-size: 0.75rem;
      margin-bottom: 0.25rem;
    }
  }

  .actionButtons {
    display: flex;
    font-size: 0.875rem;
    font-weight: $normal;
    justify-content: flex-end;
    margin-top: 3rem;
    text-transform: uppercase;

    .cancelButton {
      color: $action;
      margin-right: 1.5rem;
    }

    .submitButton {
      background-color: $dark-sky-blue;
      color: $white;

      &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
      }
    }
  }

}
