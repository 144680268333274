@import 'colors';

.logo {
  width: 13.75rem;
  height: 10rem;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  margin: 0.5rem auto;
}
