@import 'colors';
@import 'sizes';

.message {
    width: calc(70% - #{$side-bar-width});
    background-color: $white-five;
    border-radius: 4px;
    margin-top: 1.5rem;
    margin-left: 22%;
    .title {
        padding-top: 1rem;
        margin-left: 1.5rem;
        font-size: 0.875rem;
    }

    .content {
        margin: 1rem 1rem 0 1.5rem;
        padding-bottom: 1rem;
        font-size: 0.875rem;
    }
}
