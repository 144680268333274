@import 'sizes';

.requestActions {
  position: fixed;
  box-sizing: border-box;
  padding: 2rem;
  right: 0;
  width: $request-actions-size;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 0.8rem;

  .submitButton {
    margin-bottom: 0.5rem;
  }
}
