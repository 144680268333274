@import 'colors';

.requestTitleContainer {
  border-bottom: 1px solid $pale-grey-four;

  .formTitle {
    padding: 1rem 1.5rem 0.5rem 1.5rem;
    font-size: 0.75rem;
    font-weight: bold;
    color: $brownish-grey;
  }

  .textBoxcontainer {
    padding: 0 7rem 2rem 1.5rem;

    &.emptyTitle {
      background-color:rgba($warning, 0.1);
      border-left: 2px solid $warning;
    }

    .titleLabel {
      margin-bottom: 0.3rem;
      padding-top: 1.2rem;
      font-size: 0.875rem;
      color: $brownish-grey;
    }

    .requestTitle {
      font-size: 1.5rem;
      color: $dark;
    }

    .input {
      background-color: $white;
      ::placeholder {
        font-size: 1.25rem;
        font-style: italic;
        color: $blue-grey;
      }
    }
  }

  .requestTitleLabel {
    display: flex;
    vertical-align: center;
    padding: 1rem 1.5rem 1rem 1.5rem;

    .title {
      cursor: text;
      font-size: 1.5rem;
      color: $dark;
    }

    .pencilIcon {
      cursor: pointer;
      margin-left: 1rem;
      color: $cloudy-blue-four;
      font-size: 1.5rem;
    }  
  }
}
