@import 'colors';

.signUpForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .signUpButton {
        width: 100%;
        margin-top: 1.5rem;
        &:hover {
            box-shadow: none;
        }
    }

    .errorCard {
        position: absolute;
        margin: -2.6rem 0 0 25.4rem;
        @media screen and (max-width: 1080px) {
            position: relative;
            margin: 1rem 0 0 1rem;
        }

    }

    .helperText {
        max-width: 22rem;
        align-self: center;
        text-align: justify;
    }
}
