@import 'colors';
@import 'sizes';

.requestsLayout {
  display: flex;
  height: 100vh;

  .requestsContainer {
    background-color: $background;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    height: 100vh;

    .requestContent {
      flex-grow: 1;
      overflow-y: auto;
      position: relative;

      .loader {
        top: 50%;
        left: 50%;
        position: absolute;
      }
    }
  }
}
