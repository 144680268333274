@import 'colors';

.snackbar {
  width: 19rem;
  
  .close {
    cursor: pointer;
    font-size: 0.875rem;
    color: $cloudy-blue-four;
    margin-right: 0.7rem;
  }
  
  .message {
    font-size: 0.875rem;
    color: $white;
  }
}
