@import 'colors';

.resetPasswordPage {
  width: 25rem;
  margin: 2rem auto 0 auto;
  .branding {
    position: absolute;
    bottom: 0.5rem;
    width: auto;
    margin-left: 8.5rem;
  } 
}
