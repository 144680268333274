@import 'colors';

.helperText {
  color: $success;
  font-size: 0.875rem;
  margin-top: 0.5rem;
  margin-left: 1.5rem;

  &.error {
    color: $error;
  }
}
