@import 'colors';
@import 'sizes';

.pageBackground {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(../../assets/images/geometry.jpg);
  background-size: cover;

  .ieNotSupportedCard {
    width: 36.9375rem;
    height: 37.75rem;
    padding: 4.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: white;
    border-radius: 0.25rem;

    .logos {
      display: flex;
      flex-direction: column;
      align-items: center;

      .synapseLogo {
        width: 15rem;
      }

      .ieNotSupportedIcon {
        padding: 2rem 0;
        width: 6.5rem;
      }
    }

    .title {
      font-size: 1.4rem;
      font-weight: 600;
      color: $black;
    }

    .content {
      font-size: 1.2rem;
      line-height: 1.64;
    }

    .urlLabel {
      font-size: 1rem;
      font-weight: 500;
      color: $greyish-brown-two;
      margin: 0;
    }

    .copyButton {
      background-color: rgba($dark-sky-blue, 0.15);
      color: $dark-sky-blue;
      margin: 0.3rem -0.5rem 0.3rem 0.3rem;
    }
  }
}
