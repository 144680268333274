$warning: #FFA000;
$success: #21ba45;
$error: #f8535a;
$white: #FFFFFF;
$white-two: #e6e6e6;
$white-five: #e2e2e2;
$pale-grey-four: #EDEFF3;
$steel: #868E95;
$black: #141313;
$brownish-grey: #645e5e;
$blue-grey: #7282a1;
$pale-grey-two: #f1f3f5;
$dark-sky-blue: #4381e2;
$cloudy-blue-four: #ced4de;
$greyish-brown-two: #3c3939;
$black-two: #141313;
$greyish-brown: #4a4a4a;
$watermelon: #F85359;
$background: $pale-grey-two;
$action: $blue-grey;
$text: $black-two;
$requestCategory: $brownish-grey;
$isDraft: #afb8ca;
$isSubmitted: $warning;
$isRejected: $error;
$isAccepted: $success;
$dark: #12151b;
