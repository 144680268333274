@import 'colors';
@import 'fonts';

.confirmModal { 
  background-color: $white;

  .congratsSection {
    display: flex;
    margin: 1rem 1rem 0 1rem;

    .confirmIcon {
      color: $success;
      font-size: 5rem;
    }
    
    .congrats {
      max-width: 25rem;
      height: 3rem;
      margin: 1rem 0 0 1rem;
      font-size: 1.25rem;
      font-weight: bold;
      color: $greyish-brown-two;
    }
  }

  .subMessage {
    max-width: 30rem;
    margin: 1rem 1rem 0 2rem;
    font-size: 0.875rem;
    line-height: 1.44;
    color: $black-two;
  }

  .closeButton {
    background-color: $dark-sky-blue;
    font-size: 0.875rem;
    color: $white;
    font-weight: $normal;
    text-transform: uppercase;
    margin: 2.5rem 2.5rem 1rem 0;
    float: right;
  }
}
