@import 'colors';

.triggerButton {
  color: $action;
  text-transform: none;
  border: 0.04rem solid $pale-grey-four;
  align-self: center;
  line-height: normal;
  margin: 0.5rem 0;

  .contactIcon {
    font-size: 1.6rem;
    margin-right: 0.5rem;
  }

  .arrowIcon {
    padding-left: 0.5rem;
    font-size: 1rem;
  }
}
