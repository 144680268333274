@import 'colors';

.submitted {
  padding-left: 0.5rem;

  .label {
    color: $steel;
  }

  .updatedAt {
    margin-top: 0.5rem;
  }
}
