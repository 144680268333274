@import 'colors';

.title {
  font-size: 2.5rem;
  white-space: nowrap;
  margin-left: -5%;
}

.message {
  font-size: 1rem;
  margin-top: 3rem;
  line-height: 1.63;
  color: $black;
}
