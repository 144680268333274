@import 'colors';

.sendIcon {
  margin-left: 0.5rem;
}

.toolTip {
  padding: 1rem 2rem 0 2rem;
  max-width: none;
  height: 2rem;
  opacity: 0.9;
  background-color: $black;
  font-size: 0.875rem;
}
