.loginPage {
  display: flex;
  height: 100vh;

  .interactSection {
    display: flex;
    flex-direction: column;
    max-width: 40rem;
    min-width: 30rem;
    overflow-y: auto;
    justify-content: center;

    .branding {
      position: absolute;
      bottom: 0.5rem;
      width: auto;
      margin-left: 11.5rem;
    }

    > *:not(:last-child) {
      margin-bottom: 1rem;
    }
  }

  .backgroundImage {
    float: right;
    width: 100%;
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
}
