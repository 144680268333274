@import 'colors';

.helperCard {
  width: 22.625rem;
  height: 12.3125rem;
  border-radius: 0.25rem;
  box-shadow: 0 0 0.75rem 0 rgba(0, 0, 0, 0.15);
  border: solid 1px $white-two;
  background-color: $white;
  margin: 10rem auto;
  .promptText {
    font-size: 0.875rem;
    font-weight: bold;
    color: $greyish-brown;
    margin-top: 1.5rem;
    margin-left: 1.5rem;
    .errorHolder {
      margin-top: 1.2rem;
    }
  }
}
