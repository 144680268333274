@import 'colors';
@import 'fonts';

.deleteModal {
    .title {
        font-size: 1.25rem;
        color: $greyish-brown-two;
        font-weight: $bold;
        margin: 2rem 0rem 1.5rem 2rem;
    }

    .description {
        margin-left: 2rem;
    }

    .actionButtons {
        margin: 4rem 2rem 2rem 10rem;
        display: flex;
        justify-content: flex-end;

        .cancelButton {
            color: $action;  
            margin-right: 1.5rem;
        }
    
        .deleteButton {
            background-color: $error;
            color: $white;  
        }
    }
}
