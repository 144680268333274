@import 'colors';
@import 'fonts';

.submitModal {
    .title {
        font-size: 1.25rem;
        color: $greyish-brown-two;
        font-weight: $bold;
        margin: 2rem 0rem 1.5rem 2rem;
    }

    .description {
        font-size: 0.875rem;
        font-weight: $normal;
        margin-left: 2rem;
    }

    .actionButtons {
        margin: 4rem 2rem 2rem 10rem;
        display: flex;
        justify-content: flex-end;

        .cancelButton {
            color: $action;
            font-size: 0.875rem;
            font-weight: $normal;
            text-transform: uppercase;
            margin-right: 1.5rem;
        }
    
        .submitButton {
            background-color: $dark-sky-blue;
            font-size: 0.875rem;
            color: $white;
            font-weight: $normal;
            text-transform: uppercase;
        }
    }
}
