@import 'colors';

.formInput {
  min-width: 25rem;
  height: 2.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  [class~='MuiOutlinedInput-input'] {
    padding: 0.6rem;
  }
  ::placeholder {
    font-size: 0.875rem;
    font-style: italic;
    color: $blue-grey;
  }
}

.isEmpty {
  background-color: $background;
}

.hasError {
  background-color: rgba($watermelon, 0.15);
}

.helperText {
  margin: 0.25rem 0rem 0rem;
  font-size: 0.875rem;
  color: $watermelon;
}

.label {
  margin-top: 1.2rem;
  font-size: 0.875rem;
  color: $brownish-grey;
}

.eyeIcon {
  cursor: pointer;
}
