@import 'colors';
@import 'fonts';

.userInfoPopup {
  max-width: 30rem;
  padding: 1rem;
  padding-bottom: 0;

  .popupRow {
    min-width: 23rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 0.7rem;

    &.popupActions {
      justify-content: flex-end;
    }

    .textDescription {
      color: $steel;
      font-weight: $light;
    }

    .logoutButton {
      padding: 0.2rem 1rem;
      font-weight: $bold;
      color: $warning;

      &:hover {
        background-color: lighten($warning, 40%);
      }
    }
  }
}
