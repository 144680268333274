@import 'colors';

.setPasswordButton {
  width: 25rem;
  max-height: 2.5rem;
  margin-top: 2.5rem;
}

.redirectMessage {
  margin-top: 2rem;
  font-size: 0.875rem;
  color: $black-two;
}

.errorCard {
  position: absolute;
  margin: -2.6rem 0 0 25.4rem;
}
